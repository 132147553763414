import React, { FunctionComponent } from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

interface Props {
  image?: ImageDataLike;
  alt?: string;
  className?: string;
}

const Image: FunctionComponent<Props> = ({
  image,
  alt = '',
  className = '',
}) => {
  if (!image) {
    return <></>;
  }
  const fetched = getImage(image);

  if (!fetched) {
    return <></>;
  }
  return <GatsbyImage image={fetched} alt={alt} className={className} />;
};

export { Image };
