import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Link from '../common/Link';

interface Props {
  to?: string;
  href?: string;
  children: React.ReactNode;
}

const Button: FunctionComponent<Props> = ({ to, href, children, ...props }) => {
  return (
    <ButtonAppearance>
      {to && (
        <Link
          to={to}
          activeClassName={'active'}
          partiallyActive={true}
          {...props}
        >
          {children}
        </Link>
      )}
      {!to && <button {...props}>{children}</button>}
    </ButtonAppearance>
  );
};

const ButtonAppearance = styled.div`
  width: auto;
  a,
  button {
    width: auto;
    display: inline-block;
    text-transform: uppercase;
    background: #fb607f;
    cursor: pointer;
    border: none;
    width: auto;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    padding: 8px 56px 8px 16px;
    text-align: left;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
    position: relative;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    &:after {
      content: '→';
      position: absolute;
      right: 16px;
      top: 8px;
    }
    &:hover {
      background: #222;
      color: #fff !important;
      padding-right: 64px;
    }

    &.theme-usa {
      background-color: #f63933;

      :active,
      :hover {
        background-color: #333;
      }
    }
  }
`;

export default Button;
