import React, { FunctionComponent } from 'react';
import MarkdownIt from 'markdown-it';
import styled from 'styled-components';

const Markdown = new MarkdownIt({ html: true });

const Container = styled.div`
  img {
    max-width: 100%;
  }
}
`;

interface MarkdownRenderProps {
  content: string;
  alternative?: boolean;
  className?: string;
}

const MarkdownRender: FunctionComponent<MarkdownRenderProps> = ({
  content,
  className = '',
  alternative = false,
}) => {
  const html = Markdown.render(content || '');
  return (
    <Container
      className={`editor-text ${className} ${
        alternative ? 'editor-text-blue' : ''
      }`}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export { MarkdownRender };
