import React, { FunctionComponent, useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";

export type LinkButtonStyle = 'blue' | 'navy' | 'pink' | 'white' | 'yellow';
type IconColor = 'navy' | 'yellow' | 'blue';

interface LinkItem {
  label: string;
  url: string;
}

interface LinkButtonProps {
  cta?: string;
  ctas?: string[];
  urls?: string[];
  url?: string;
  color: LinkButtonStyle;
  iconColor?: IconColor;
  linkItems?: LinkItem[];
}

interface ButtonIconProps {
  color: IconColor;
}

const ButtonIcon: FunctionComponent<ButtonIconProps> = ({ color }) => (
  <svg
    className={`flex-none fill-current text-${color}`}
    width="10.105"
    height="18"
    viewBox="0 0 10.105 18"
  >
    <path d="M9.781,8.192,1.913.323a1.139,1.139,0,0,0-1.59,0,1.139,1.139,0,0,0,0,1.59L7.41,9,.323,16.087a1.139,1.139,0,0,0,0,1.59,1.139,1.139,0,0,0,1.59,0l7.868-7.9A1.139,1.139,0,0,0,9.781,8.192Z" />
  </svg>
);

const LinkButton: React.FC<LinkButtonProps> = ({ ctas = ['Read More'],
  cta,
  urls,
  url,
  color,
  iconColor,
  linkItems, }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const anchorOrigin = {
    vertical: "top",
    horizontal: "center",
  };

  const transformOrigin = {
    vertical: "bottom",
    horizontal: "center",
  };

  let ic = iconColor;

  if (ic === undefined) {
    ic = color === 'navy' ? 'yellow' : 'navy';
  }
  const textColor = color === 'white' || color === 'yellow' ? 'navy' : 'white';


  if (cta || ctas.length === 1) {
    if (cta) ctas[0] = cta;
    return (
      <div
        className={`
      flex flex-row flex-nowrap items-center content-start space-x-4 pl-6 pr-8 py-3 bg-${color}
      border-solid border border-${color} hover:bg-opacity-0 focus:bg-opacity-0
      font-worksans font-semibold rounded-full text-xs text-${textColor}
      hover:text-${color} focus:text-${color} uppercase
      transition duration-500 ease-in-out
    `}
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (url) {
            window.location.href = url;
          } else if (urls && urls[0]) {
            window.location.href = urls[0];
          }
        }}
      >

        <ButtonIcon color={ic} />
        <span className="flex-1">{ctas[0]}</span>
      </div>
    );
  }

  return (
    <>
      <div
        className={`
                flex flex-row flex-nowrap items-center content-start space-x-4 pl-6 pr-8 py-3 bg-${color}
                border-solid border border-${color} hover:bg-opacity-0 focus:bg-opacity-0
                font-worksans font-semibold rounded-full text-xs text-${textColor}
                hover:text-${color} focus:text-${color} uppercase
                transition duration-500 ease-in-out
              `}
        aria-controls="simple-menu"
        style={{ cursor: "pointer" }}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ButtonIcon color={ic} />
        <span className="flex-1">
          Book Now
        </span>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {ctas.map((cta, index) => (
          <MenuItem
            key={cta}
            onClick={() => {
              handleClose();
              window.location.href = urls[index];
            }}
          >
            {cta}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export { LinkButton };