import React, { FunctionComponent } from 'react';
import MarkdownIt from 'markdown-it';
import styled from 'styled-components';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

const Markdown = new MarkdownIt({ html: true });

const Container = styled.div`
  img {
    max-width: 100%;
  }
`;
interface TextProps {
  children: string;
}

const Text: FunctionComponent<TextProps> = ({ children }) => {
  const html = Markdown.render(children || '');
  const hasYoutubeVideo =
    html.includes('<iframe') && html.includes('src="https://www.youtube');
  let finalHtml = html;
  let youtubeCode = null;
  
  if (hasYoutubeVideo) {
    const regex = /\/embed\/([^"/]+)/;
    const match = html.match(regex);
    youtubeCode = match ? match[1] : null;
    const contentWithoutVideo = html.replace(
      /<iframe[^>]*>.*?<\/iframe>/gi,
      ''
    );
    finalHtml = contentWithoutVideo;
  }

  return (
    <>
      <Container dangerouslySetInnerHTML={{ __html: finalHtml }} />
      {youtubeCode && (
        <>
          <style jsx global>{`
            html,
            body {
              padding: 0;
              font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                sans-serif;
            }

            .yt-lite {
              background-color: #000;
              position: relative;
              display: block;
              contain: content;
              background-position: center center;
              background-size: cover;
              cursor: pointer;
            }

            /* responsive iframe with a 16:9 aspect ratio
                      thanks https://css-tricks.com/responsive-iframes/
                  */
            .yt-lite::after {
              content: '';
              display: block;
              padding-bottom: calc(100% / (16 / 9));
            }
            .yt-lite > iframe {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }

            /* play button */
            .yt-lite > .lty-playbtn {
              width: 70px;
              height: 46px;
              background-color: #212121;
              z-index: 1;
              opacity: 0.8;
              border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
              transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
            }
            .yt-lite:hover > .lty-playbtn {
              background-color: #f00;
              opacity: 1;
            }
            /* play button triangle */
            .yt-lite > .lty-playbtn:before {
              content: '';
              border-style: solid;
              border-width: 11px 0 11px 19px;
              border-color: transparent transparent transparent #fff;
            }

            .yt-lite > .lty-playbtn,
            .yt-lite > .lty-playbtn:before {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
            }

            /* Post-click styles */
            .yt-lite.lyt-activated {
              cursor: unset;
            }
            .yt-lite.lyt-activated::before,
            .yt-lite.lyt-activated > .lty-playbtn {
              opacity: 0;
              pointer-events: none;
            }
          `}</style>
          <LiteYouTubeEmbed
            id={youtubeCode}
            title={'Youtube video'}
            noCookie={true}
            poster="sddefault"
          />
        </>
      )}
    </>
  );
};

export { Text };
