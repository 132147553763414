import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

interface Props {
  children: React.ReactNode;
  fullWidth?: boolean;
}

const Container: FunctionComponent<Props> = ({
  children,
  fullWidth = false,
  ...restProps
}) => {
  return (
    <StyledGridContainer {...restProps}>
      <Inner
        container={true}
        spacing={2}
        alignItems={fullWidth ? 'stretch' : 'flex-start'}
        style={fullWidth ? { maxWidth: '1000px' } : {}}
      >
        {children}
      </Inner>
    </StyledGridContainer>
  );
};

const StyledGridContainer = styled.div`
  background: #f9f9f9;
`;

const Inner = styled(Grid)``;

export default Container;
