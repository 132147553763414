import React, { FunctionComponent } from 'react';
import { RedirectItem } from '../RedirectItem';
import { Text } from '../../../components/Page/text';

interface RedirectContentProps {
  title?: string;
  content?: string;
  items: RedirectItem[];
  props: any;
}

const RedirectContent: FunctionComponent<RedirectContentProps> = ({
  title,
  content,
  items,
  props,
}) => (
  <div className="relative items-center text-center px-6 md:max-w-4xl mx-auto mt-8 md:mt-18 mb-8 md:mb-18">
    <div className="mb-12 max-w-xl mx-auto">
      {title && (
        <>
          <h1 className="font-fave text-6xl font-bold text-navy">{title}</h1>
          <img
            className="mx-auto mb-6"
            src="/images/highlight-underline-blue.svg"
            alt="Brush stroke dividing sections"
          />
        </>
      )}
      {content && <Text {...props}>{content}</Text>}
    </div>
    <div className="relative space-y-10 md:space-y-0 md:grid md:grid-cols-12 gap-10 mx-auto z-10">
      {items.map((data, i) => (
        <div
          className="md:col-span-4 min-h-88 bg-white items-center rounded-3xl overflow-hidden transition-all shadow-lg"
          key={i}>
          <img className="h-32 w-full object-cover" src={data.image} loading="lazy"></img>
          <div className="relative p-6 pb-20 md:pb-16 lg:pb-20 xl:pb-18 content-height">
            <h1 className="text-2xl md:text-lg mb-2 font-bold text-left">
              {data.title}
            </h1>
            <div className="text-xs text-left">
              <Text {...props}>{data.content}</Text>
            </div>
            <a
              type="button"
              className="absolute bottom-8 md:bottom-8 lg:bottom-10 left-1/2 transform -translate-x-1/2 trending-now-link whitespace-nowrap inline-flex"
              href={data.link}
            >
              <div className="h-10 px-6 flex items-center bg-pink rounded-full text-white text-xs font-semibold uppercase">
                  <div className="inline-flex m-auto align-middle">
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="19.246"
                      viewBox="0 0 10.623 19.246"
                    >
                      <path
                        id="Caminho_63"
                        data-name="Caminho 63"
                        d="M-3256.945-9565l8.916,8.916-8.916,8.916"
                        transform="translate(3257.652 9565.707)"
                        fill="none"
                        stroke="#fff5a8"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>
                      Read more
                  </div>
              </div>
            </a>
          </div>
        </div>
      ))}
    </div>
    <img
      className="absolute -right-52 bottom-12"
      src="/svg/homepage-orange-right.svg"
      alt="Orange brush stroke positioned on the right bottom side"
    />
  </div>
);

export { RedirectContent };
